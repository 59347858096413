export enum AI_CHATBOT_ACTIONS {
  POPULATE_CHAT_MESSAGES = "POPULATE_CHAT_MESSAGES",
  ADD_USER_MESSAGE_ACTION = "ADD_USER_MESSAGE",
  ADD_AI_RESPONSE_ACTION = "ADD_AI_RESPONSE",
  SET_IS_SENDING_ACTION = "SET_IS_SENDING",
  SET_SHOW_EMPTY_STATE_ACTION = "SET_SHOW_EMPTY_STATE_ACTION",
  SET_SCROLL_TO_BOTTOM_ACTION = "SET_SCROLL_TO_BOTTOM_ACTION",
  SET_SHOW_SCROLL_TO_BOTTOM_BUTTON_ACTION = "SET_SHOW_SCROLL_TO_BOTTOM_BUTTON_ACTION",
  SET_IS_STREAMING_RESPONSE = "SET_IS_STREAMING_RESPONSE",
  SET_IS_CREATING_NEW_SESSION = "SET_IS_CREATING_NEW_SESSION",
  CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES",
  SET_CAN_CLONE_FROM_PREVIOUS_CHAT = "SET_CAN_CLONE_FROM_PREVIOUS_CHAT",
  SET_IS_CLONING_FROM_PREVIOUS_CHAT = "SET_IS_CLONING_FROM_PREVIOUS_CHAT",
  SET_IS_AVAILABLE_IN_CURRENT_SUBSCRIPTION = "SET_IS_AVAILABLE_IN_CURRENT_SUBSCRIPTION",
  SET_USER_SUBSCRIPTION_TIER = "SET_USER_SUBSCRIPTION_TIER",
  RESET = "RESET",
}
