import { Expose } from "class-transformer";
import { BONUSX_SUBSCRIPTION_TIERS } from "../../enums/bonusx-subscription-tiers.enum";
import { IUserSubscriptionDto } from "./user-subscription.dto.interface";

export class UserSubscriptionDto implements IUserSubscriptionDto {
  @Expose()
  id!: number;

  @Expose()
  citizenId!: number;

  @Expose()
  subscriptionId!: string;

  @Expose()
  subscriptionTier!: BONUSX_SUBSCRIPTION_TIERS;

  @Expose()
  providerCode!: string;

  @Expose()
  providerCustomerId!: string;

  @Expose()
  startDate!: Date;

  @Expose()
  endDate!: Date;

  @Expose()
  createdAt!: Date;

  @Expose()
  updatedAt!: Date;
}
